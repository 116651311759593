import t from '@jetshop/intl';
import useLoginFields from '@jetshop/core/components/Auth/useLoginFields';
import useSignupForm from '@jetshop/core/components/Auth/useSignupForm';
import { TrendButton } from '@jetshop/ui/Button';
import { css } from 'linaria';
import React from 'react';
import Input from '../../Forms/Input';
import { GlobalError } from '../../Forms/GlobalError';
import { useSignupValidation } from './useSignupValidation';

const sectionStyle = css`
  margin: 2rem 0 5rem;
  .validation-message {
    margin-top: 1rem;
  }
`;

export default function LoginFields(props) {
  const { fields } = useLoginFields();
  const {
    globalError,
    isSubmitting,
    validationDetails,
    isSignupError
  } = useSignupForm();

  const validationMessage = useSignupValidation(validationDetails);
  return (
    <section className={sectionStyle}>
      <h1 className="heading">{t('Email & Password')}</h1>

      {globalError && (
        <GlobalError style={{ marginBottom: '2em' }}>{globalError}</GlobalError>
      )}

      {fields.map(field => {
        return <Input {...field.inputProps} />;
      })}

      <TrendButton type="submit" disabled={isSubmitting}>
        {isSubmitting ? t('Hold on a moment…') : t('Create Profile')}
      </TrendButton>
      {isSignupError && (
        <div className="validation-message">{validationMessage}</div>
      )}
    </section>
  );
}
