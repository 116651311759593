import useAddressFields from '@jetshop/core/components/Auth/useAddressFields';
import { css, cx } from 'linaria';
import React from 'react';
import Input from '../../Forms/Input';

const sectionStyle = css`
  margin-top: 2em;
`;

export default function Address(props) {
  const { fields } = useAddressFields();

  return (
    <section className={cx(sectionStyle)}>
      {fields.map(field => {
        // Map over billingAddressFields and display them
        return <Input {...field.inputProps} />;
      })}
    </section>
  );
}
