import React from 'react';
import {
  useResetPasswordForm,
  ResetPasswordProvider
} from '@jetshop/core/components/Auth/ResetPasswordProvider';
import InputWithLabel from '../Forms/Input';
import t from '@jetshop/intl';
import ButtonWithLoading from '../ui/Button';
import { GlobalError } from '../Forms/GlobalError';
import Head from '@jetshop/core/components/Head';
import { Link } from 'react-router-dom';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { AuthMaxWidth } from './UI/StyledComponents';

export default function ResetPasswordPage() {
  return (
    <ResetPasswordProvider>
      <ResetPassword />
    </ResetPasswordProvider>
  );
}

function ResetPassword(props) {
  const {
    globalError,
    passwordInputProps,
    isSubmitting,
    isValid,
    submitted
  } = useResetPasswordForm();
  const { routes } = useShopConfig();

  return (
    <AuthMaxWidth>
      <Head data={{ title: 'Reset Password' }} />
      <h1>{t('Reset password')}</h1>
      <section>
        {submitted ? (
          <>
            <p>{t('Your password has been reset.')}</p>
            <p>
              {t.rich('You can now {login} with your new password', {
                login: <Link to={routes.login.path}>Log in</Link>
              })}
            </p>
          </>
        ) : (
          <>
            <InputWithLabel
              {...passwordInputProps}
              label={t('Please enter your new password')}
            />

            {globalError && (
              <GlobalError style={{ marginBottom: '1em' }}>
                {globalError}
              </GlobalError>
            )}

            <ButtonWithLoading
              loading={isSubmitting}
              type="submit"
              disabled={!isValid}
            >
              {t('Submit')}
            </ButtonWithLoading>
          </>
        )}
      </section>
    </AuthMaxWidth>
  );
}
