import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import TrendButton from '../ui/Button';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { AuthMaxWidth } from './UI/StyledComponents';
import SiteContentContext from '../SiteContent/SiteContentContext';
import { useContext } from 'react';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
`;

const LogInPageMaxWidth = styled(AuthMaxWidth)``;

const LoginHeader = styled('header')`
  text-align: center;
`;

const LoginBody = styled('div')`
  label {
    display: none;
  }
`;

const LoginAction = styled('div')`
  text-align: center;
`;

const TextLink = styled(Link)`
  display: block;
  margin: 2rem 0;
  text-decoration: none;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

function LogInPage() {
  const { routes } = useShopConfig();
  const { LoginText: item } = useContext(SiteContentContext);
  return (
    <LogInPageMaxWidth>
      <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
      <LogInFormProvider redirect="my-pages">
        {({ globalError, isSubmitting, isValid }) => (
          <section>
            <LoginHeader>
              <h1>{t('Login')}</h1>
              <TextLink to={routes.signup.path} className="signup-link">
                {item?.html ? item.html : t('Not yet a member? Sign Up')}
              </TextLink>
            </LoginHeader>
            <LoginBody>
              <Input type="email" name="email" label={t('E-mail address')} />
              <Input type="password" name="password" label={t('Password')} />

              {globalError && (
                <GlobalError style={{ marginBottom: '2em' }}>
                  {globalError}
                </GlobalError>
              )}
            </LoginBody>
            <LoginAction>
              <StyledTrendButton
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                loadingText={t('Hold on a moment...')}
              >
                {t('Log in')}
              </StyledTrendButton>
              <TextLink to={routes.forgotPassword.path}>
                {t('Forgot password?')}
              </TextLink>
            </LoginAction>
          </section>
        )}
      </LogInFormProvider>
    </LogInPageMaxWidth>
  );
}

export default LogInPage;
