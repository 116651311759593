import { css } from 'linaria';
import { theme } from '../../Theming/Theming';

export const smallSection = css`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 600px;
  max-width: 100%;
  select {
    height: 40px;
    width: 100%;
    padding: 0 10px;
  }
  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  ${theme.below.sm} {
    width: 100%;
  }
`;

export const activeSegment = `
  position: relative;
  ::before {
    content: '';
    position: absolute;
    left: -1.5rem;
    top: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    background: #eee;
    transition: opacity 0.4s ease;
  }
`;
