import t from '@jetshop/intl';
import { ErrorMessage, Field } from 'formik';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { faEnvelope, faLock } from '@fortawesome/pro-light-svg-icons';
import {
  InputError,
  InputPositive,
  ToggleViewPasswordButton
} from './InputStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Input = styled('input')`
  height: 50px;
  background: #fcfcfc;
  border: 1px solid #dedede;
  padding: 10px;
  width: 100%;
  margin-bottom: 0.25rem;
  padding-right: 40px;
  &[data-icon='true'] {
    padding-left: 40px;
  }
  &[data-disabled='true'] {
    background: #ffffff;
    color: #808080;
  }
`;

export const Wrapper = styled('div')`
  margin-bottom: 1.5rem;
`;

export const ErrorSpan = styled('span')`
  font-style: italic;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
`;

export const Label = styled('label')`
  display: block;
  font-size: 12px;
  margin-bottom: 0.25rem;
  display: block;
  .req {
    color: hsl(0, 0%, 60%);
    margin-left: 0.5em;
  }
  &[data-disabled='true'] {
    color: #808080;
  }
`;

const InputWrapper = styled('div')`
  position: relative;
`;

const InputIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: calc(50% - 10px);
  left: 15px;
`;

const getIcon = type => {
  switch (type) {
    case 'password':
      return faLock;
    case 'email':
      return faEnvelope;
    default:
      return null;
  }
};

const InputWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  required,
  type,
  disableValidation,
  maxlength = 9999,
  ...props
}) => {
  const [currentType, setType] = useState(type);

  const iconType = getIcon(type);

  return (
    <Wrapper className={props.wrapperClassName}>
      <Label data-disabled={disabled} htmlFor={name}>
        <span>{label || name}</span>
        {required && <span className="req">{t('(Required)')}</span>}
      </Label>
      <Field
        data-disabled={disabled}
        id={name}
        name={name}
        render={({ field, form: { touched, errors } }) => {
          return (
            <InputWrapper>
              {iconType && <InputIcon icon={iconType} />}
              <Input
                {...props}
                {...field}
                type={currentType}
                data-icon={iconType ? 'true' : 'false'}
                maxlength={maxlength}
              />
              {type === 'password' && (
                <ToggleViewPasswordButton
                  className={currentType === 'password' ? 'hidden' : 'visible'}
                  onClick={() => {
                    currentType === 'password'
                      ? setType('text')
                      : setType('password');
                  }}
                />
              )}
              {!disableValidation && (
                <>
                  {touched?.field?.name && errors?.field?.name && (
                    <InputError />
                  )}
                  {touched?.field?.name && !errors?.field?.name && (
                    <InputPositive />
                  )}
                </>
              )}
            </InputWrapper>
          );
        }}
      />
      {!disableValidation && <ErrorMessage name={name} component={ErrorSpan} />}
    </Wrapper>
  );
};

export default InputWithLabel;
