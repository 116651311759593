import MaxWidth from '../../Layout/MaxWidth';
import { theme } from '../../Theming/Theming';
import { styled } from 'linaria/react';

export const AuthMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  padding: 0 2rem;
  padding-top: 2rem;
  padding-bottom: 5rem;
  ${theme.above.md} {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  > form {
    min-width: 300px;
  }
  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;
